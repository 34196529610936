<template>
     <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal newModal" :class="{'modalZindex':safariTrue}">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title" v-if="!showProduct">Offer Applicable For</span>
                    <span class="v-modal-dialog-title v-modal-title" v-if="showProduct">Select Offer Products</span>
                    <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-0" style="max-height: 400px !important;overflow-x: hidden !important;overflow-y: auto !important;">
                    <div class="v-modal-layout">
                        <div style="background:#00448b !important;" class="pt-6 pb-3">
                            <div class="d-flex">
                                <div class="col-md-3"></div>
                                <div class="col-md-4 newstepline" :class="{'newsteplineapplied':showProduct}">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/showproduct.svg" alt="" class="newsteps">
                                    <div class="pt-2 whiteSpace font-weight-bold text-white">Offer Applicable <br> Products</div>
                                </div>
                                <div class="col-md-4" v-if="!showProduct">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/orangepercent.svg" alt="" class="newstepspercent">
                                    <div class="pt-2 whiteSpace font-weight-bold text-white">Select Offer <br> Products</div>
                                </div>
                                <div class="col-md-4" v-if="showProduct">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/whitepercent.svg" alt="" class="newsteps">
                                    <div class="pt-2 whiteSpace font-weight-bold text-white">Select Offer <br> Products</div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-5 pt-5" v-if="!showProduct">
                            <div class="row justify-content-center" v-if="ajaxproduct">
                                <div class="digi-table-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                </div>
                            </div>
                            <div v-else>
                                <span class="font-weight-bold fs-18">Select Ordered Product to Include Offer:</span>
                                <div class="d-flex d-flex justify-content-between mt-5 ml-5 mr-10 productDetails" v-for="(p,i) in allProducts" :key="i">
                                    <div>
                                        <div class="font-weight-bold mb-3">Ordered Products</div>
                                        <span class="font-600" v-for="(ip,iip) in p.offer_applicable_item_list" :key="iip">
                                            <el-tooltip v-if="ip.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"  style="width: 13px!important;">
                                            </el-tooltip>
                                            <el-tooltip  v-else-if="ip.product_type == 'Veg'" content="Veg" placement="top">
                                                <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"  style="width: 13px!important;">
                                            </el-tooltip>
                                            <el-tooltip  v-else-if="ip.product_type == 'Egg'" content="Egg" placement="top">
                                                <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"  style="width: 13px!important;">
                                            </el-tooltip>
                                            <el-tooltip v-else-if="ip.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                <img style="width:13px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                            </el-tooltip>
                                        {{ip.product_name}}
                                        <span class="text-green"> X {{ip.quantity}}</span>
                                        <span class="font-weight-bold" v-if="iip != p.offer_applicable_item_list.length - 1">,</span>
                                        </span>
                                        <div class="font-weight-bold mt-3 borderTop pt-3" v-if="p.promotion_selected_items_list.length">Offer Applied Product</div>
                                        <span class="font-600" v-for="(ps,ips) in p.promotion_selected_items_list" :key="ips">
                                            <el-tooltip v-if="ps.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"  style="width: 13px!important;">
                                            </el-tooltip>
                                            <el-tooltip  v-else-if="ps.product_type == 'Veg'" content="Veg" placement="top">
                                                <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"  style="width: 13px!important;">
                                            </el-tooltip>
                                            <el-tooltip  v-else-if="ps.product_type == 'Egg'" content="Egg" placement="top">
                                                <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"  style="width: 13px!important;">
                                            </el-tooltip>
                                            <el-tooltip v-else-if="ps.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                <img style="width:13px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                            </el-tooltip>
                                            {{ps.product_name}}
                                            <span class="text-green"> X {{ps.quantity}}</span>
                                        <span class="font-weight-bold" v-if="ips != p.promotion_selected_items_list.length - 1">,</span>
                                        </span>
                                    </div>
                                    <i class="icon icon-chevron-right mt-3" :class="{'mt-12':p.promotion_selected_items_list.length}" style="font-size:5rem !important;" @click="productDetailsTab(p.id)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="pl-5 pt-5 pr-5" v-if="showProduct">
                            <div class="d-flex justify-content-between" v-if="!newAjax">
                                <span class="font-weight-bold fs-18">Offer to be applied for: ({{total}}/{{redeemable_max_quantity}})</span>
                                <div class="breadcome-heading d-xl-inline">
                                    <div class="form-group formsearch has-warning has-feedback">
                                        <input type="text" style="background:#fff !important;" v-model="search" @input="productDetails()" class="form-control textremove holder" placeholder="Search Product here"/>
                                        <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center" v-if="ajaxselectedProduct">
                                <div class="digi-table-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                </div>
                            </div>
                            <div v-if="!ajaxselectedProduct && getallProducts.length == 0" class="justify-content-center row mt-5">
                                <span class="font-weight-bold">No Search Result Found</span>
                            </div>
                            <div class="d-flex flex-wrap" v-else>
                                <div class="addon-main mt-4 ml-2" v-for="(product,index) in getallProducts" :key="index">
                                    <div class="variant-addon" :class="{'selected_variant':selected_product_ids.includes(product.product_id) || selected_product_ids.includes(product.variant_id) || selected_product_ids.includes(product.item_id) }">
                                        <div @click="selectProduct(product,index)">
                                            <avatar
                                                class="product-avatar content-center pt-7"
                                                v-if="product.image == ''"
                                                :username="product.product_name.replace(/ .*/,'')"
                                                :src="product.image"
                                                :size="50"
                                                :rounded="false"
                                                color="#f5a623"
                                                background-color="transparent">
                                            </avatar>
                                            <img :src="product.image" class="avatar avatar-addons" :alt="product.product_name" v-else>
                                            <p class="unavailable-addons" v-if="product.available_quantity == 0">Unavailable</p>
                                        </div>
                                        <div v-if="selected_product_ids.includes(product.product_id) || selected_product_ids.includes(product.variant_id) || selected_product_ids.includes(product.item_id)" class="input-prepend-append bg-orange addon-quantity d-flex pl-1 pr-1">
                                            <a class="btn-prepend pt-1 pointer" @click="subproduct(product,index)">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus-addon.svg" style="width:25px;transform: rotate(90deg);">
                                            </a>
                                            <input v-model.number="product.selected_quantity" @blur="productaddsub(product,index)" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" min="0" max="1000" class="form-control bg-orange w-52 text-center">
                                            <a class="btn-append pt-1 pointer" @click="addproduct(product,index)">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-addon.svg" style="width:25px">
                                            </a>
                                        </div>
                                    </div>
                                    <span class="d-flex justify-content-start mt-4">
                                        <!-- <div>
                                            <el-tooltip class="item" effect="dark" content="Non-Veg" placement="top" v-if="addon.type_id.name == 'Non-Veg'">
                                                <img  src="https://cdn.digicollect.com/cdn/posv2/non-veg.svg">
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Veg" placement="top" v-else-if="addon.type_id.name == 'Veg'">
                                                <img  src="https://cdn.digicollect.com/cdn/posv2/veg.svg">
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Egg" placement="top" v-else-if="addon.type_id.name == 'Egg'" >
                                                <img src="https://cdn.digicollect.com/cdn/posv2/egg.svg">
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Pescatarian" placement="top" v-else-if="addon.type_id.name == 'Pescatarian'">
                                                <img  style="width:16px;" src="https://cdn.digicollect.com/cdn/posv2/psec.svg">
                                            </el-tooltip>

                                        </div>     -->
                                        <div class="col addon-col">
                                            <span class="word-break text-left mt-3 font-weight-bold">{{product.product_name}}</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5" v-if="!showProduct">
                    <!-- <a class="btn btn-link btn-smm" @click="cancel">CANCEL</a> -->
                    <button class="btn btn-danger btn-smm mr-5" @click="cancel">REMOVE PROMO</button>
                    <button class="btn btn-secondary btn-smm text-uppercase" @click="applyprocess()" :disabled="ajaxproduct || disableApply">PROCEED TO CHECKOUT</button>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5" v-if="showProduct">
                    <!-- <a class="btn btn-link btn-smm" @click="cancelgoback">CANCEL</a> -->
                    <button class="btn btn-danger btn-smm mr-5" @click="cancelgoback">REMOVE PROMO</button>
                    <button class="btn btn-secondary btn-smm text-uppercase" @click="continueprocess()" :disabled="ajaxselectedProduct || selected_product_ids.length == 0 || total == 0">NEXT</button>
                </div>
            </div>
        </modal>
     </div>
</template>
<script>
import Avatar from 'vue-avatar'
import { EventBus } from './eventBus/nav-bar-event'
export default {
    props:['modal_name','storeorderId','source'],
    data(){
        return{
            ajaxcall:false,
            showProduct:false,
            allProducts:[],
            getallProducts:[],
            search:'',
            selected_products:[],
            selected_product_ids:[],
            combination_id:'',
            is_promotional_product:true,
            ajaxproduct:false,
            ajaxselectedProduct:false,
            newAjax:true,
            total:0,
            redeemable_max_quantity:0,
            disableApply:true,
            safariTrue:false
        }
    },
    components:{
        Avatar
    },
    methods:{
        cancel(){
            this.total = 0
            this.selected_products = [],
            this.selected_product_ids = []
            this.showProduct = false
            if(this.source == 'checkout'){
                this.$modal.hide(this.modal_name)
            }else if(this.source == 'paybill'){
                this.$modal.hide(this.modal_name)
                this.$emit('updateapplypaybill')
            }
            else{
                this.$modal.hide(this.modal_name)
                // this.$modal.show('promotion-active')
            }

        },
        applyprocess(){
            this.total = 0
            this.selected_products = [],
            this.selected_product_ids = []
            this.showProduct = false
            this.$swal({
                imageUrl:'https://cdn.digicollect.com/cdn/posv2/Dog3.svg',
                title: 'Yo Baby !!!',
                text: 'Coupon Applied Successfully',
                showConfirmButton:false,
                timer: 1000
            })
            if(this.source == 'checkout'){
                this.$modal.hide(this.modal_name)
                this.$emit('updateapply')
            }else if(this.source == 'paybill'){
                this.$modal.hide(this.modal_name)
                this.$emit('updateapplypaybill')
            }
            else{
                this.$emit('buyUpdated')
            }

        },
        async productDetails(){
            try {
                this.ajaxselectedProduct = true
                this.getallProducts = []
                let params={
                    search:this.search,
                    order_id:this.storeorderId,
                    combination_id:this.combination_id
                }
                let res = await this.$http.post('/promotions/get_products',params)
                if(this.newAjax){
                    this.newAjax = false
                }
                this.ajaxselectedProduct = false
                this.getallProducts = res.data.response.all_products
                this.redeemable_max_quantity = res.data.response.redeemable_max_quantity
                this.handelSelected(this.getallProducts)
                const result = [];
                const map = new Map();
                for (const item of this.getallProducts) {
                    if(!map.has(item.product_id)){
                        map.set(item.product_id, true);    // set any value to Map
                        result.push({
                            available_quantity:item,available_quantity,
                            product_id: item.product_id,
                            image:item.image,
                            isVariant:item.isVariant,
                            price:item.price,
                            product_name:item.product_name,
                            selected_quantity:item.selected_quantity,
                            variant_id:item.variant_id,
                            item_id:item.item_id || ''
                        });
                    }
                }
                this.getallProducts = result
            } catch (error) {

            }
        },
        handelSelected(params){
            const result = []
            params.forEach((item,index)=>{
                if(item.item_id){
                    result.push(item.item_id)
                }
            })
            this.selected_product_ids = result
            this.selected_product_ids = [... new Set(this.selected_product_ids.map(x => x))]
            this.handleApplied()
        },
        productDetailsTab(id){
            this.showProduct = true
            this.combination_id = id
            this.productDetails()
        },
        cancelgoback(){
            this.showProduct = false
            this.search = ''
            this.selected_products = []
            this.selected_product_ids = []
            this.combination_id = ''
            this.total = 0
        },
        async continueprocess(){
            try {
                this.ajaxproduct = true
                this.showProduct = false
                this.search = ''
                this.selected_products = []
                this.selected_product_ids = []
                this.combination_id = ''
                this.total = 0
                this.disableApply = false
                let params={
                    order_id:this.storeorderId
                }
                let res = await this.$http.post('/promotions/get_offer_applicable_products',params)
                this.ajaxproduct = false
                this.allProducts = res.data.response.product_list
            } catch (reason) {

            }

        },
        subproduct(product,index){
            if(product.selected_quantity > 0){
                this.getallProducts[index].selected_quantity--
                let params ={
                    order_id:this.storeorderId,
                    item_id:product.item_id,
                    quantity:product.selected_quantity,
                    is_promotional_product:this.is_promotional_product,
                    combination_id:this.combination_id,
                    variant_id:product.variant_id || ''
                }
                this.editItemApi(params)
            }else{
                this.$toasted.global.error('Quantity cannot be lesser than 0');
            }
        },
        addproduct(product,index){
            if(product.selected_quantity < product.available_quantity){
                this.getallProducts[index].selected_quantity++
                if(product.item_id == '' || product.item_id == undefined){
                    let params ={
                        order_id:this.storeorderId,
                        product_id:product.product_id,
                        quantity:product.selected_quantity,
                        is_promotional_product:this.is_promotional_product,
                        combination_id:this.combination_id,
                        variant_id:product.variant_id || ''
                    }
                    this.addItemApi(params)
                }else{
                   let params ={
                        order_id:this.storeorderId,
                        item_id:product.item_id,
                        quantity:product.selected_quantity,
                        is_promotional_product:this.is_promotional_product,
                        combination_id:this.combination_id,
                        variant_id:product.variant_id || ''
                    }
                    this.editItemApi(params)
                }
            }else{
                this.$toasted.global.error('Quantity cannot be greater than quantity of an Product');
            }
        },
        productaddsub(product,index){
            if(product.selected_quantity < product.available_quantity){
                if(product.selected_quantity == ''){
                    this.getallProducts[index].selected_quantity = 0
                }

                if(product.item_id == '' || product.item_id == undefined){
                let params ={
                        order_id:this.storeorderId,
                        product_id:product.product_id,
                        quantity:product.selected_quantity,
                        is_promotional_product:this.is_promotional_product,
                        combination_id:this.combination_id,
                        variant_id:product.variant_id || ''
                    }
                    this.addItemApi(params)
                }else{
                    let params ={
                        order_id:this.storeorderId,
                        item_id:product.item_id,
                        quantity:product.selected_quantity,
                        is_promotional_product:this.is_promotional_product,
                        combination_id:this.combination_id,
                        variant_id:product.variant_id || ''
                    }
                    this.editItemApi(params)
                }
            }else{
                this.getallProducts[index].selected_quantity = 0
                this.$toasted.global.error('Quantity cannot be greater than quantity of an Product');
            }
        },
        selectProduct(product,index){
            if(product.available_quantity != 0){
                let idpush = product.product_id
                if(product.isVariant){
                    idpush = product.variant_id
                }
                if(this.selected_product_ids.find(x => x === idpush)){
                    product.selected_quantity = 0
                    this.selected_product_ids = this.selected_product_ids.filter(function(x,index) {
                        if (x !== idpush) {
                            return x;
                        }
                    });
                    if(product.hasOwnProperty('item_id')){
                        let params ={
                            order_id:this.storeorderId,
                            item_id:product.item_id,
                            quantity:product.selected_quantity,
                            is_promotional_product:this.is_promotional_product,
                            combination_id:this.combination_id,
                            variant_id:product.variant_id || ''
                        }
                        this.editItemApi(params)
                    }
                }
                else{
                    product.selected_quantity = 0
                    this.selected_product_ids.push(idpush);
                }
            }
        },
        pushItemResponse(params,item_id){
            this.getallProducts.forEach((product,index)=>{
                if(params.variant_id == ''){
                    if(product.product_id == params.product_id){
                        product.item_id = item_id
                    }
                }else{
                    if(product.variant_id == params.variant_id){
                        product.item_id = item_id
                    }
                }

            })
        },
        removeItemId(item_id,temp_item_id){
            this.getallProducts.forEach((product,index)=>{
                if(product.item_id == item_id){
                    product.item_id = temp_item_id
                }
            })
        },
        async addItemApiCall(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/mobile/orders/items', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async addItemApi(params){
            try {
                let res = await this.addItemApiCall(params)
                this.pushItemResponse(params,res.item_id)
                this.$toasted.global.success('Product Quantity Updated Successfully');
                this.handleApplied()
            } catch (reason) {
                this.$toasted.global.error(reason);
                this.handleAddResponseAdd(params)
                this.handleApplied()
            }
        },
        handleAddResponseAdd(params){
            this.getallProducts.forEach((product,index)=>{
                if(params.variant_id == ''){
                    if(product.product_id == params.product_id){
                        if(product.selected_quantity > 0){
                            this.getallProducts[index].selected_quantity--
                        }
                    }
                }else{
                    if(product.variant_id == params.variant_id){
                        if(product.selected_quantity > 0){
                            this.getallProducts[index].selected_quantity--
                        }
                    }
                }
            })
        },
        handleAddResponseEdit(params){
            this.getallProducts.forEach((product,index)=>{
                if(product.item_id == params){
                    if(product.selected_quantity > 0){
                        this.getallProducts[index].selected_quantity--
                    }
                }
            })
        },
        async editItemApiCall(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/mobile/orders/items/edit', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async editItemApi(params){
            try {
                let res = await this.editItemApiCall(params)
                this.removeItemId(params.item_id,res.item_id)
                this.$toasted.global.success('Product Quantity Updated Successfully');
                this.handleApplied()
            } catch (reason) {
                this.$toasted.global.error(reason);
                this.handleAddResponseEdit(params.item_id)
                this.handleApplied()
            }

        },
        handleApplied(){
            const result1 = []
            this.getallProducts.forEach((item,index)=>{
                if(item.selected_quantity > 0){
                    result1.push(item.selected_quantity)
                }
            })
            let sum = 0;
            for (var i = 0; i < result1.length; i++) {
                sum += result1[i];
            }
            this.total = sum
        }
    },
    mounted(){
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        EventBus.$on('detailproduct',params=>{
            this.ajaxproduct = true
            this.$http.post('/promotions/get_offer_applicable_products',params).then(res=>{
                this.ajaxproduct = false
                if(res.data.status_id == 1){
                    this.allProducts = res.data.response.product_list
                }else{
                    this.$toasted.global.error(res.data.reason);
                    this.$modal.hide(this.modal_name)
                }
            })
        })
    }
}
</script>
<style scoped>
.productDetails{
    background: #fff !important;
    padding: 20px !important;
    border-radius: 8px !important;
}
.whiteSpace{
    white-space: nowrap !important;
}
.newModal .v-modal-body{
    padding: 0px 0px 15px 0px !important
}
.addon-main{
    width: 145px;
    margin-left: 7px;
}
.variant-addon{
    cursor: pointer;
    width: 120px;
    height: 88px;
    text-align: center;
    border-radius: 7px;
    padding-top: 17px;
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.variant-addon-img{
    cursor: pointer;
    width: 174px;
    height: 95px;
    text-align: center;
    border-radius: 7px;
    padding-top: 5px;
    -webkit-box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.selected_variant{
    background-color: #00448b;
    color: #fff;
}
.content-center{
    margin: 0 auto;
    position: relative;
    bottom: 12px;
}
.w-52{
    width: 62px !important;
}
.addon-col{
    padding-left: 8px;
    margin-top: 2px;
    padding-right: 8px;
}
.avatar-addons {
    font-size: 50px !important;
    line-height: 50px;
    background-color: transparent!important;
}
.avatar-addons {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background-color: transparent!important;
}
.borderTop{
    border-top: 1px solid rgba(0, 0, 0, 0.16) !important;
    width: 500px;
}
.newsteps{
    padding: 10px !important;
    background: #f5a623 !important;
    border-radius: 50%;
}
.newstepspercent{
   padding: 10px !important;
    background: #fff !important;
    border-radius: 50%;
}
.newstepline::before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 95%;
  height: 4px;
  background-color: #fff;
  z-index: 0;
  left: 26%;
}
.newsteplineapplied::before{
  background-color: #f5a623 !important;
}
</style>